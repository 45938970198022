"use client";
import { useState, useRef, useEffect } from "react";
import { useRouter } from "next/navigation";
import { BodyText } from "@/components/common/texts/body-text";
import Image from "next/image";
import { CategoryType } from "@/types/common";
import { useSearchParams } from "next/navigation";
import * as gtag from "./../../../../../lib/gtag";
import StickyBox from "react-sticky-box";
import { useUpdateSearchScroll } from "@/store/update-search-scroll";
import { useUpdateShowNavMobile } from "@/store/update-show-nav-mobile";
interface Props {
  propertiesCategoryData: CategoryType[];
}

const HorizontalListView = ({ propertiesCategoryData }: Props) => {
  const { showHeaderNav } = useUpdateShowNavMobile((state) => state);

  return (
    <>
      <StickyBox
        offsetTop={70}
        className="relative mobile:hidden block z-40 w-full bg-white pt-10"
      >
        <List propertiesCategoryData={propertiesCategoryData} />
      </StickyBox>

      <StickyBox
        offsetTop={showHeaderNav ? 142 : 92}
        className="hidden mobile:block z-40 w-full bg-white pt-1"
      >
        <List propertiesCategoryData={propertiesCategoryData} />
      </StickyBox>
    </>
  );
};

export default HorizontalListView;

interface ListProps {
  propertiesCategoryData: CategoryType[];
}

const List = ({ propertiesCategoryData }: ListProps) => {
  const { isScrolled } = useUpdateSearchScroll((state) => state);
  const [selectedIndex, setSelectedIndex] = useState(58);
  const listRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const searchParams = useSearchParams();

  const [categoryData, setCategoryData] = useState([
    ...propertiesCategoryData,
    ...propertiesCategoryData,
    ...propertiesCategoryData,
    ...propertiesCategoryData,
    ...propertiesCategoryData,
  ]);

  // Create refs for each item
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (listRef.current) {
      const isMobile = window.innerWidth <= 768; // Check if the screen is mobile-sized
      const scrollMultiplier = isMobile ? 0.875 : 0.83; // Use 0.415 for mobile, 0.2 for others
      listRef.current.scrollLeft =
        listRef.current.scrollWidth * scrollMultiplier;
    }
    const currentCategory = searchParams.get("category");
    if (currentCategory) {
      var lastIndex =
        categoryData.findIndex((e) => e.category == currentCategory) +
        propertiesCategoryData.length;
      setSelectedIndex(lastIndex);
      scrollToCenter(lastIndex);
    }
  }, []);

  const handleClick = (index: number) => {
    setSelectedIndex(index);
    let selectedCategory = categoryData[index];
    let selectedCategoryName = selectedCategory.category;
    router.push(
      selectedCategory.category == "all_properties"
        ? "/"
        : `/?category=${selectedCategoryName}`
    );
    gtag.event({
      action: `${selectedCategoryName}_category_clicked`,
      category: "engagement",
      label: "category was explored",
      value: 1,
    });
    // Scroll the selected item into the center
    scrollToCenter(index);
  };

  const scrollToCenter = (index: number) => {
    if (!itemRefs.current || !listRef.current) return;

    const selectedItem = itemRefs.current[index];
    if (selectedItem && listRef.current) {
      const containerWidth = listRef.current.offsetWidth;
      const itemLeft = selectedItem.offsetLeft;
      const itemWidth = selectedItem.offsetWidth;
      const scrollTo =
        itemLeft -
        listRef.current.offsetLeft -
        containerWidth / 2 +
        itemWidth / 2;

      listRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const container = listRef.current;
    if (!container) return;
    const handleScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      const scrollPosition = scrollLeft + clientWidth;
      const threshold = scrollWidth * 0.75;
      if (scrollPosition >= threshold) {
        setCategoryData((prev) => [...prev, ...propertiesCategoryData]);
      }
    };

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [listRef, propertiesCategoryData]);

  return (
    <div
      style={{
        boxShadow: isScrolled ? "0px 6px 10.1px 0px #00000026" : "",
        transition: "all 500ms",
      }}
      className="w-full"
    >
      <div className="overflow-x-auto w-full hide-scrollbar" ref={listRef}>
        <Blur isRight={false} />
        <div className="flex min-w-max border-b-[0.5px] border-neutralN6 gap-x-5 mobile:gap-x-3">
          {categoryData.map((category, index) => {
            const isSelected =
              index === selectedIndex ||
              categoryData[selectedIndex].category == category.category;
            return (
              <div
                key={index}
                // @ts-ignore
                ref={(el) => (itemRefs.current[index] = el)} // Assign ref to each item
                className={`${index === 0 && "!pl-24 mobile:!pl-14"}
              ${index === categoryData.length - 1 && "!pr-24 mobile:!pr-14"}
              px-2.5
              py-2
              pb-4
          cursor-pointer
          whitespace-nowrap
          ${isSelected ? "border-b-[3px] border-neutralN9" : ""}
          flex gap-x-2 items-center`}
                onClick={() => handleClick(index)}
              >
                <IconImage
                  className="h-6 w-6"
                  src={isSelected ? category["selectedIcon"] : category["icon"]}
                />
                <BodyText
                  className={`${
                    isSelected ? "text-neutralN9" : "text-neutralN8"
                  }`}
                >
                  {category["category"].replaceAll("_", " ")}
                </BodyText>
              </div>
            );
          })}
        </div>
        <Blur isRight />
      </div>
    </div>
  );
};

type IconImageProps = {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
};

const IconImage: React.FC<IconImageProps> = ({
  src,
  alt = "Icon",
  width = 24,
  height = 24,
  className = "",
}) => {
  return (
    <Image
      className={className}
      src={src}
      alt={alt}
      width={width}
      height={height}
    />
  );
};

const Blur = ({ isRight }: { isRight: boolean }) => {
  return (
    <div
      aria-hidden
      style={{
        background:
          "linear-gradient(90deg,rgba(255, 255, 255, 0) 29.61%,#ffffff 67.84%)",
      }}
      className={`-translate-y-1/2 top-[68.5%] z-20 absolute ${
        isRight ? "right-0" : "left-0 rotate-180"
      } h-[60px] w-[200px] mobile:w-[100px] mobile:h-[40px] mobile:top-[64%]`}
    />
  );
};
