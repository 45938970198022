import { create } from "zustand";

interface UpdateSearchScroll {
  isScrolled: boolean;
  setIsScrolled: (isScrolled: boolean) => void;
}

export const useUpdateSearchScroll = create<UpdateSearchScroll>((set) => {
  return {
    isScrolled: false,
    setIsScrolled: (isScrolled) =>
      set(() => ({
        isScrolled,
      })),
  };
});
