export const airbnbMapStyle = [
  {
    featureType: "all",
    elementType: "geometry",
    stylers: [
      {
        lightness: "50",
      },
      {
        saturation: "0",
      },
      {
        gamma: "1",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels",
    stylers: [
      {
        lightness: "25",
      },
      {
        saturation: "0",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.business",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export const mapContainerStyle = {
  width: "100%",
  height: "600px",
};

export const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  styles: airbnbMapStyle,
};
