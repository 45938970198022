"use client";

import { useEffect, useRef, useState } from "react";
import { Guests } from "@/types/common";
import { BodyText } from "../common/texts/body-text";
import { Guest } from "../common/guest";
import { NotSelectedText, SelectedText } from "./main-search-bar-texts";
import {
  HeaderNotSelectedText,
  HeaderSelectedText,
} from "./header-search-bar-texts";
import {
  BookingSummaryNotSelectedText,
  BookingSummarySelectedText,
} from "./booking-summary-texts";

interface Props {
  handleGuests: (guests: Guests) => void;
  mainPage?: boolean;
  header?: boolean;
  guests: Guests;
  children?: React.ReactNode;
  bookingSummary?: boolean;
  maxGuests?: number;
  isGuestsOpen: boolean;
  setIsGuestsOpen: (isOpen: boolean) => void;
}

export const AddGuests = ({
  guests,
  handleGuests,
  mainPage,
  header,
  children,
  bookingSummary,
  maxGuests,
  isGuestsOpen,
  setIsGuestsOpen,
}: Props) => {
  const [noOfAdults, setNoOfAdults] = useState(guests.noOfAdults);
  const [noOfChildren, setNoOfChildren] = useState(guests.noOfChildren);
  const [noOfPets, setNoOfPets] = useState(guests.noOfPets);
  // const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const handleDropdownClick = () => {
    setIsGuestsOpen(!isGuestsOpen);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;

      // Check if click is outside both trigger and dropdown content
      if (
        triggerRef.current &&
        !triggerRef.current.contains(target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(target)
      ) {
        setIsGuestsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsGuestsOpen]);

  useEffect(() => {
    handleGuests({
      noOfAdults,
      noOfChildren,
      noOfPets,
      noOfTotalGuests: noOfAdults + noOfChildren + noOfPets,
    });
  }, [noOfAdults, noOfChildren, noOfPets]);

  useEffect(() => {
    setNoOfAdults(guests.noOfAdults);
    setNoOfChildren(guests.noOfChildren);
    setNoOfPets(guests.noOfPets);
  }, [guests.noOfAdults, guests.noOfChildren, guests.noOfPets]);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setIsGuestsOpen(false);
    });
  }, []);

  return (
    <>
      <div
        className={`w-[25%] ${bookingSummary && "!w-auto"} ${
          header && "!w-auto flex flex-col justify-center gap-y-1"
        } mobile:w-auto`}
        ref={triggerRef}
        onClick={handleDropdownClick}
      >
        <div
          className={`flex flex-col text-left cursor-pointer ${
            isGuestsOpen && "mobile:!rounded-b-none"
          } ${isGuestsOpen && "opacity-75"} ${
            header && "h-auto w-auto px-0 py-0 flex flex-col gap-y-1"
          } ${
            bookingSummary &&
            "border-[0.5px] border-neutralN6 !rounded-[2.5px] !p-2.5 !bg-white gap-y-2"
          } ${
            mainPage &&
            "h-auto w-full px-4 {text}-[#5F5853] flex flex-col !border-0 gap-y-2"
          } mobile:border-[0.5px] mobile:w-full mobile:border-neutralN6 mobile:rounded-[2.5px] mobile:p-2.5 mobile:flex mobile:flex-col`}
        >
          {children}

          {!header && !bookingSummary ? (
            guests.noOfTotalGuests ? (
              <SelectedText text={`${guests.noOfTotalGuests} guests`} />
            ) : (
              <NotSelectedText text="add guests" />
            )
          ) : null}

          {bookingSummary ? (
            guests.noOfTotalGuests ? (
              <BookingSummarySelectedText
                text={`${guests.noOfTotalGuests} guests`}
              />
            ) : (
              <BookingSummaryNotSelectedText text="add guests" />
            )
          ) : null}

          {header ? (
            guests.noOfTotalGuests ? (
              <HeaderSelectedText text={`${guests.noOfTotalGuests} guests`} />
            ) : (
              <HeaderNotSelectedText text="add guests" />
            )
          ) : null}

          <BodyText
            className={`hidden mobile:block !font-medium !text-[16px] ${
              guests.noOfTotalGuests > 0 ? "text-primaryB7" : "text-neutralN6"
            }`}
          >
            {guests.noOfTotalGuests > 0
              ? `${guests.noOfTotalGuests} guests`
              : "add guests"}
          </BodyText>
        </div>
      </div>

      {isGuestsOpen && (
        <div
          ref={dropdownRef}
          style={{
            left: mainPage ? "50%" : "0%",
            width: mainPage ? "50%" : "100%",
            boxShadow: "0px 1px 2px 0px #00000040",
          }}
          className="border border-neutralN5 mobile:border-0 mt-2 mobile:mt-0 absolute z-[42] mobile:static top-[100%] h-auto p-[30px] mobile:p-0 rounded-[2.5px] bg-neutralN2 mobile:bg-transparent"
        >
          <AddGuestsDropdownContent
            noOfAdults={noOfAdults}
            noOfChildren={noOfChildren}
            noOfPets={noOfPets}
            setNoOfAdults={setNoOfAdults}
            setNoOfChildren={setNoOfChildren}
            setNoOfPets={setNoOfPets}
            maxGuests={maxGuests}
            totalGuests={noOfAdults + noOfChildren + noOfPets}
          />
        </div>
      )}
    </>
  );
};

interface AddGuestsProps {
  noOfAdults: number;
  noOfChildren: number;
  noOfPets: number;
  setNoOfAdults: (update: (prev: number) => number) => void;
  setNoOfChildren: (update: (prev: number) => number) => void;
  setNoOfPets: (update: (prev: number) => number) => void;
  maxGuests?: number;
  totalGuests?: number;
}

const AddGuestsDropdownContent = ({
  noOfAdults,
  noOfChildren,
  noOfPets,
  setNoOfAdults,
  setNoOfChildren,
  setNoOfPets,
  maxGuests,
  totalGuests,
}: AddGuestsProps) => {
  return (
    <div className="flex flex-col gap-y-2">
      <Guest
        guestType="Adults"
        setNoOfGuest={setNoOfAdults}
        noOfGuest={noOfAdults}
        maxGuests={maxGuests}
        totalGuests={totalGuests}
        labelText="above 18y"
      />
      <Guest
        guestType="Children"
        setNoOfGuest={setNoOfChildren}
        noOfGuest={noOfChildren}
        maxGuests={maxGuests}
        totalGuests={totalGuests}
        labelText="between 3y to 18y"
      />
      <Guest
        guestType="Pets"
        setNoOfGuest={setNoOfPets}
        noOfGuest={noOfPets}
        maxGuests={maxGuests}
        totalGuests={totalGuests}
      />
    </div>
  );
};
