"use client";

import { Dispatch, SetStateAction } from "react";
import { BodyText } from "./common/texts/body-text";

interface Props {
  value: string;
  option: string;
  setOption: Dispatch<SetStateAction<string>>;
  handleSelectValue: (value: string) => void;
  text: string;
}

export const RadioOption = ({
  value,
  option,
  setOption,
  handleSelectValue,
  text,
}: Props) => {
  return (
    <label
      style={{
        borderColor: option === value ? "#7B472B" : "#D5CABD",
      }}
      className="w-full gap-x-2.5 bg-transparent custom-radio flex items-center cursor-pointer"
    >
      <input
        type="radio"
        name="city"
        className="form-radio text-brown-600"
        checked={option === value ? true : false}
        value={value}
        onChange={({ target }) => {
          handleSelectValue(target.value);
          setOption(target.value);
        }}
      />
      <span className="custom-radio-button" />
      <BodyText
        className={`bg-white ${
          option === value
            ? "text-primaryB6 border-primaryB6"
            : "text-neutralN6 border-neutralN6"
        } rounded-[2.5px] p-2.5 border w-[90%]`}
      >
        {text}
      </BodyText>
    </label>
  );
};
