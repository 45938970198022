"use client";

import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-selector.css";
import { Lexend_Deca } from "next/font/google";
import { format } from "date-fns";
import toast from "react-hot-toast";
import * as gtag from "./../../../lib/gtag";
import { NotSelectedText, SelectedText } from "./main-search-bar-texts";
import {
  HeaderNotSelectedText,
  HeaderSelectedText,
} from "./header-search-bar-texts";
import {
  BookingSummaryNotSelectedText,
  BookingSummarySelectedText,
} from "./booking-summary-texts";

export const lexendDeca = Lexend_Deca({
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  subsets: ["latin"],
});

interface Props {
  handleDates: (dates: [null | Date, null | Date]) => void;
  mainPage?: boolean;
  header?: boolean;
  checkInDate: Date | null;
  checkOutDate: Date | null;
  children?: React.ReactNode;
  bookedDays?: Date[];
  isBookingSummary?: boolean;
  isCheckIn?: boolean;
  isDatesOpen: boolean;
  setIsDatesOpen: Dispatch<SetStateAction<boolean>>;
}

export const DesktopDateSelector = ({
  handleDates,
  mainPage,
  header,
  children,
  bookedDays,
  isBookingSummary,
  checkInDate,
  checkOutDate,
  isCheckIn,
  isDatesOpen,
  setIsDatesOpen,
}: Props) => {
  const datePickerRef = useRef<DatePicker>(null);
  const [bookedMaxDate, setBookedMaxDate] = useState<null | Date>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const [bookedDaysArray, setBookedDaysArray] = useState<Date[]>([]);
  // Use this to track when both dates are successfully selected
  const [bothDatesSelected, setBothDatesSelected] = useState(false);
  // Add this to prevent closing on the first selection
  const [isFirstSelection, setIsFirstSelection] = useState(true);
  // Add this to prevent immediate click outside detection
  const [ignoreClickOutside, setIgnoreClickOutside] = useState(false);

  const getPreviousDate = (date: Date): Date => {
    const previousDate = new Date(date);
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate;
  };

  const getNextDate = (date: Date): Date => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    return nextDate;
  };

  const isBlocked = (date: Date): boolean => {
    if (!bookedDays) return false;

    const bookedSet = new Set(
      bookedDays.map((day) => new Date(day).toISOString().split("T")[0])
    );

    const formattedDate = new Date(date).toISOString().split("T")[0];

    return bookedSet.has(formattedDate);
  };

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    gtag.event({
      action: `date_change_half`,
      category: "data_input",
      label: "User trying to change date",
      value: 1,
    });

    // When a date is selected, temporarily ignore outside clicks
    setIgnoreClickOutside(true);
    setTimeout(() => setIgnoreClickOutside(false), 300);

    // If we're selecting the first date, update our tracking state
    if (start && !end) {
      setIsFirstSelection(false);
    }

    // If we're clearing the selection, reset our flags
    if (!start && !end) {
      setIsFirstSelection(true);
      setBothDatesSelected(false);
    }

    if (
      checkInDate &&
      start &&
      !end &&
      checkInDate.getTime() === start.getTime()
    ) {
      setBookedMaxDate(null);
      handleDates([null, null]);
      setIsFirstSelection(true);
      return;
    }

    if (start && end && start.getTime() === end.getTime()) {
      setBookedMaxDate(null);
      handleDates([null, null]);
      setIsFirstSelection(true);
      return;
    }

    if (
      start &&
      isBlocked(start) && // Current date is blocked or excluded
      !isBlocked(getPreviousDate(start)) && // Previous date is not blocked or excluded
      isBlocked(getNextDate(start))
    ) {
      handleDates([null, null]);
      toast.error("This date is already booked");
      setIsFirstSelection(true);
      return;
    }

    if (
      start &&
      end &&
      isBlocked(end) && // Current date is blocked or excluded
      !isBlocked(getPreviousDate(end)) && // Previous date is not blocked or excluded
      isBlocked(getNextDate(end))
    ) {
      handleDates([start, end]);
      // Set flag when both dates are selected
      setBothDatesSelected(true);
      return;
    }

    if (
      start &&
      end &&
      isBlocked(end) && // Current date is blocked or excluded
      !isBlocked(getPreviousDate(end)) && // Previous date is not blocked or excluded
      !isBlocked(getNextDate(end))
    ) {
      setBookedMaxDate(null);
      handleDates([start, end]);
      // Set flag when both dates are selected
      setBothDatesSelected(true);
      return;
    }

    const sortedBookedDays = bookedDaysArray.sort(
      (a, b) => a.getTime() - b.getTime()
    );

    const nextBookedDate = sortedBookedDays.find(
      (bookedDate) => start && bookedDate > start
    );

    if (start && nextBookedDate) {
      setBookedMaxDate(nextBookedDate);

      if (end && end >= nextBookedDate) {
        toast.error(
          "Selected date range includes blocked dates. Adjusting end date."
        );
      } else {
        handleDates([start, end]);
        // Set flag when both dates are selected
        if (start && end) {
          setBothDatesSelected(true);
        }
      }
    } else if (start) {
      handleDates([start, end]);
      // Set flag when both dates are selected
      if (start && end) {
        setBothDatesSelected(true);
      }
    }
  };

  // Reset first selection state when modal opens
  useEffect(() => {
    if (isDatesOpen) {
      setIsFirstSelection(!checkInDate);
    }
  }, [isDatesOpen, checkInDate]);

  // Effect to close modal after both dates are selected
  useEffect(() => {
    if (bothDatesSelected && checkInDate && checkOutDate) {
      const timer = setTimeout(() => {
        setIsDatesOpen(false);
        setBothDatesSelected(false);
        setIsFirstSelection(true);
      }, 300); // Increased timeout for better user experience

      return () => clearTimeout(timer);
    }
  }, [bothDatesSelected, checkInDate, checkOutDate, setIsDatesOpen]);

  //@ts-ignore
  const dayClassName = (date) => {
    const today = new Date();
    const previousDate = new Date(date);
    previousDate.setDate(previousDate.getDate() - 1);

    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);

    // Reset time to ensure accurate comparison
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    previousDate.setHours(0, 0, 0, 0);
    nextDate.setHours(0, 0, 0, 0);

    if (date < today) {
      return "past-date"; // Class for past dates
    }

    bookedDaysArray.forEach((bookedDay) => bookedDay.setHours(0, 0, 0, 0));

    if (checkInDate && !checkOutDate && bookedMaxDate && date > bookedMaxDate) {
      return "excluded-date";
    }

    if (
      checkInDate &&
      checkInDate < date &&
      isBlocked(date) && // Current date is blocked or excluded
      !isBlocked(previousDate) && // Previous date is not blocked or excluded
      !isBlocked(nextDate) // Next date is blocked or excluded
    ) {
      if (checkOutDate) {
        if (checkOutDate.getTime() === date.getTime()) {
          return "not-excluded-date";
        }
      } else {
        return "not-excluded-date";
      }
    }

    if (
      checkInDate &&
      checkInDate < date &&
      isBlocked(date) && // Current date is blocked or excluded
      !isBlocked(previousDate) && // Previous date is not blocked or excluded
      isBlocked(nextDate) // Next date is blocked or excluded
    ) {
      if (checkOutDate) {
        if (checkOutDate.getTime() === date.getTime()) {
          return "not-excluded-date";
        }
      } else {
        return "not-excluded-date";
      }
    }

    // Check if the date is part of the booked days (excluded dates)
    const isExcludedDate = bookedDaysArray.some(
      (blockedDate) => date.getTime() === blockedDate.getTime()
    );

    if (isExcludedDate) {
      return "excluded-date";
    }

    return "";
  };

  useEffect(() => {
    if (!checkInDate && !checkOutDate) setBookedMaxDate(null);
  }, [checkInDate, checkOutDate, setBookedMaxDate]);

  // Improved click outside handler with debounce mechanism
  useEffect(() => {
    // We need this handler to ONLY run if someone clicks completely outside
    // both the date picker container AND the trigger element
    const handleClickOutside = (event: MouseEvent) => {
      // If we should ignore outside clicks, do nothing
      if (ignoreClickOutside) return;
      
      // If we're in the process of selecting the second date, don't close yet
      if (checkInDate && !checkOutDate && !isFirstSelection) return;

      const target = event.target as Node;

      // Get all elements with the react-datepicker class
      const datePickerElements = document.querySelectorAll(
        ".react-datepicker, .react-datepicker__day, .react-datepicker__month, .react-datepicker__day-name, .react-datepicker__header"
      );

      // Check if the click is inside any datepicker element
      let isClickInsideDatePicker = false;
      datePickerElements.forEach((element) => {
        if (element.contains(target)) {
          isClickInsideDatePicker = true;
        }
      });

      // Also check if click is inside our calendar container ref
      if (calendarRef.current && calendarRef.current.contains(target)) {
        isClickInsideDatePicker = true;
      }

      // Only close if click is outside both trigger and datepicker
      if (
        !isClickInsideDatePicker &&
        triggerRef.current &&
        !triggerRef.current.contains(target)
      ) {
        setIsDatesOpen(false);
      }
    };

    // Only add the listener if the date picker is open
    if (isDatesOpen) {
      // Add a slight delay before attaching the click handler
      const timer = setTimeout(() => {
        document.addEventListener("mousedown", handleClickOutside);
      }, 100);
      
      return () => {
        clearTimeout(timer);
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }

    return () => {};
  }, [isDatesOpen, setIsDatesOpen, checkInDate, checkOutDate, isFirstSelection, ignoreClickOutside]);

  useEffect(() => {
    if (bookedDays) setBookedDaysArray(bookedDays);
    else setBookedDaysArray([]);
  }, [bookedDays]);

  // More conservative scroll handler
  useEffect(() => {
    const handleScroll = () => {
      // Only close on significant scrolling
      if (window.scrollY > 50) {
        setIsDatesOpen(false);
      }
    };

    if (isDatesOpen) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isDatesOpen, setIsDatesOpen]);

  const renderCalendarContainer = (props: any) => {
    return (
      <div
        ref={calendarRef}
        onClick={(e) => {
          // Prevent event propagation on calendar container clicks
          e.stopPropagation();
          // When calendar is interacted with, temporarily ignore outside clicks
          setIgnoreClickOutside(true);
          setTimeout(() => setIgnoreClickOutside(false), 100);
        }}
      >
        <div {...props}>{props.children}</div>
      </div>
    );
  };

  return (
    <>
      <div
        ref={triggerRef}
        onClick={(e) => {
          e.stopPropagation();
          if (isDatesOpen) {
            setIsDatesOpen(false);
          } else {
            datePickerRef.current?.setFocus();
            setIsDatesOpen(true);
          }
        }}
        className={`relative cursor-pointer ${
          header &&
          "mx-0 !w-auto h-auto px-0 py-0 flex flex-col justify-center gap-y-1"
        } ${mainPage && "h-full w-[20%] py-2 px-4 flex flex-col gap-y-2"}  ${
          isBookingSummary &&
          "!w-1/2 p-2.5 flex flex-col !bg-white border-[0.5px] border-neutralN6 rounded-[2.5px] gap-2"
        }`}
      >
        {children}

        {isBookingSummary && isCheckIn ? (
          checkInDate ? (
            <BookingSummarySelectedText
              text={`${format(checkInDate, "dd MMM, yy")}`}
            />
          ) : (
            <BookingSummaryNotSelectedText text="add dates" />
          )
        ) : null}

        {isBookingSummary && !isCheckIn ? (
          checkOutDate ? (
            <BookingSummarySelectedText
              text={`${format(checkOutDate, "dd MMM, yy")}`}
            />
          ) : (
            <BookingSummaryNotSelectedText text="add dates" />
          )
        ) : null}

        {!header && !isBookingSummary && isCheckIn ? (
          checkInDate ? (
            <SelectedText text={`${format(checkInDate, "dd MMM, yy")}`} />
          ) : (
            <NotSelectedText text="add dates" />
          )
        ) : null}

        {!header && !isBookingSummary && !isCheckIn ? (
          checkOutDate ? (
            <SelectedText text={`${format(checkOutDate, "dd MMM, yy")}`} />
          ) : (
            <NotSelectedText text="add dates" />
          )
        ) : null}

        {header && isCheckIn ? (
          checkInDate ? (
            <HeaderSelectedText text={`${format(checkInDate, "dd MMM, yy")}`} />
          ) : (
            <HeaderNotSelectedText text="add dates" />
          )
        ) : null}

        {header && !isCheckIn ? (
          checkOutDate ? (
            <HeaderSelectedText
              text={`${format(checkOutDate, "dd MMM, yy")}`}
            />
          ) : (
            <HeaderNotSelectedText text="add dates" />
          )
        ) : null}
      </div>

      {isDatesOpen && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            // When calendar container is clicked, temporarily ignore outside clicks
            setIgnoreClickOutside(true);
            setTimeout(() => setIgnoreClickOutside(false), 100);
          }}
          className="datepicker-wrapper"
        >
          <DatePicker
            ref={datePickerRef}
            selected={checkInDate}
            onChange={onDateChange}
            //@ts-ignore
            startDate={checkInDate}
            //@ts-ignore
            endDate={checkOutDate}
            selectsRange
            placeholderText="add dates"
            dayClassName={dayClassName}
            popperPlacement="bottom-start"
            open={isDatesOpen}
            shouldCloseOnSelect={false}
            calendarContainer={renderCalendarContainer}
            monthsShown={header || isBookingSummary ? 1 : 2}
            inline
          />
        </div>
      )}
    </>
  );
};