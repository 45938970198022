// Define the type for the event parameters
export type GAEventParams = {
  action: string;
  category: string;
  label: string;
  value: number;
};

// Function to send GA events using the gtag function on window
export const event = ({
  action,
  category,
  label,
  value,
}: GAEventParams): void => {
  if (typeof window !== "undefined" && typeof window.gtag === "function") {
    console.log("Getting logged: " + action + "label: " + label);
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};
