"use client";

import { HeroPropertyType } from "../../../../types/properties_type";
import { PropertyCategory } from "../../../../../data/category_data";
import { getHomeScreenPrices } from "@/lib/prices-data";
import { ListingCard } from "@/components/listingCard";
import { getTag, shuffle } from "@/lib/utils";
import { useState, useEffect, useRef } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { BodyText } from "@/components/common/texts/body-text";
import { ListingsSkeleton } from "@/components/listings-skeleton";

interface Props {
  propertiesData: HeroPropertyType[];
  propertiesCategoryData: PropertyCategory[];
  category?: string;
  initialProperties?: HeroPropertyType[];
}

export const GridListView = ({
  propertiesData,
  propertiesCategoryData,
  category,
}: Props) => {
  const [properties, setProperties] = useState<HeroPropertyType[]>([]);
  const [finalProperties, setFinalProperties] = useState<HeroPropertyType[]>(
    []
  );
  const [n, setN] = useState(10);
  const [isMobile, setIsMobile] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);
  const prevNRef = useRef(n);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const prices = await getHomeScreenPrices();
        const updatedProperties = propertiesData.map((property) => {
          const propertyPrices = prices[property.propertyId] || {};

          if (!propertyPrices.isAvailable) {
            return {
              ...property,
              total_price: +(propertyPrices.total_price / 2).toFixed(0),
              discounted_price: +(propertyPrices.total_price / 2).toFixed(0),
              discount_percentage: 0,
              isAvailable: propertyPrices.isAvailable,
            };
          }
          return {
            ...property,
            total_price: +(propertyPrices.total_price / 2).toFixed(0),
            discounted_price: +(propertyPrices.discounted_price / 2).toFixed(0),
            discount_percentage: +(
              propertyPrices.discount_percentage / 2
            ).toFixed(0),
            isAvailable: propertyPrices.isAvailable,
          };
        });
        setProperties(updatedProperties);
      } catch (error) {
        setProperties(propertiesData);
      }
    };

    fetchPrices();
  }, []);

  useEffect(() => {
    if (category && properties.length > 0) {
      const index = propertiesCategoryData.findIndex(
        (e) => e.category === category
      );
      if (index === -1) {
        setFinalProperties([]);
        return;
      }
      const selectedProperties = propertiesCategoryData[index].properties;
      const filtered = properties.filter((property) =>
        selectedProperties
          .map((id) => id.trim().toLowerCase())
          .includes(property.id.trim().toLowerCase())
      );
      setFinalProperties(shuffle(filtered));
    } else {
      setFinalProperties(shuffle(properties));
    }
  }, [category, propertiesCategoryData]);

  useEffect(() => {
    if (n < prevNRef.current) {
      if (sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
    prevNRef.current = n;
  }, [n]);

  const getItemCount = isMobile ? n : n + 10;
  const isMoreInList = finalProperties.length > getItemCount;
  const isButtonHidden = !isMoreInList && n === 10;

  const viewText = isMoreInList ? (
    <span className="flex items-center gap-x-1.5">
      <span>view more</span>
      <RiArrowDropDownLine size={20} />
    </span>
  ) : (
    <span className="flex items-center gap-x-1.5">
      <span>view less</span>
      <RiArrowDropUpLine size={20} />
    </span>
  );

  // Function to handle view tap
  const handleViewTap = (event: React.FormEvent) => {
    event.preventDefault();
    setN((prevN) => (isMoreInList ? prevN + 10 : Math.max(10, prevN - 10)));
  };

  if (finalProperties.length === 0) return <ListingsSkeleton />;

  return (
    <section ref={sectionRef}>
      <div className="w-full grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 auto-rows-auto mobile:gap-y-5">
        {finalProperties.slice(0, getItemCount).map((item, index) => (
          <ListingCard
            avgRating={item.avgRating}
            tag={getTag({ tag: item.tag ? item.tag : null })}
            index={index}
            key={item.propertyId}
            property={item}
            //@ts-ignore
            isAvailable={item.isAvailable}
          />
        ))}
      </div>

      <button
        className={`${
          isButtonHidden ? "hidden" : ""
        } mt-[45px] mx-auto flex items-center justify-center h-[34px] w-auto rounded-[2.5px] border border-neutralN7 px-[25px]`}
        onClick={handleViewTap}
      >
        <BodyText className="!leading-[16px] text-neutralN8 mobile:!text-[14px]">
          {viewText}
        </BodyText>
      </button>
    </section>
  );
};
