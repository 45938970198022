import { SearchDataType } from "@/types/common";
import { create } from "zustand";

interface UpdateHomeSearchData {
  searchData: SearchDataType;
  setSearchData: (searchData: SearchDataType) => void;
}

export const useUpdateHomeSearchData = create<UpdateHomeSearchData>((set) => {
  return {
    searchData: {
      guests: {
        noOfChildren: 0,
        noOfAdults: 0,
        noOfPets: 0,
        noOfTotalGuests: 0,
      },
      checkInDate: null,
      checkOutDate: null,
      city: "",
    },
    setSearchData: (searchData) =>
      set(() => ({
        searchData,
      })),
  };
});
