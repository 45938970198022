import { BodyText } from "@/components/common/texts/body-text";
import { LabelText } from "@/components/common/texts/label-text";
import { ReviewType } from "@/types/common";
import { format, isValid } from "date-fns";
import Image from "next/image";
import React from "react";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
interface Props {
  review: ReviewType;
}

export const Review = ({ review }: Props) => {
  const {
    reply,
    reviewDescription,
    rating,
    reviewDate,
    reviewPlatform,
    reviewerImage,
    reviewerName,
  } = review;

  const parsedDate = new Date(reviewDate);
  const formattedReviewDate = isValid(parsedDate)
    ? format(parsedDate, "MMMM yyyy").toLowerCase()
    : "January 2025";
  const formattedReplyDate = reply?.replyDate
    ? format(new Date(reply?.replyDate), "MMMM yyyy").toLowerCase()
    : null;

  return (
    <div className="w-full">
      <VerticalGap>
        <HorizontalGap>
          <Image
            width={500}
            height={500}
            src={
              reviewerImage
                ? reviewerImage
                : "https://res.cloudinary.com/dz3tveb47/image/upload/v1731485977/default-avatar_booaf8.png"
            }
            alt="Reviewer Image"
            className="w-[45px] h-[45px] object-cover rounded-full"
          />
          <div className="flex flex-col gap-y-0.5">
            <NameText name={reviewerName} />
            <ContentText content={`booked on ${reviewPlatform}`} />
          </div>
        </HorizontalGap>
        <div className="flex flex-col gap-y-1">
          <div className="flex items-center gap-x-1">
            <Rating rating={rating} />
            <ContentText content={formattedReviewDate} />
          </div>
          <DescriptionText description={reviewDescription} />
        </div>
      </VerticalGap>
      {reply && (
        <div className="w-[90%] mt-4 ml-[10%]">
          <VerticalGap>
            <HorizontalGap>
              <Image
                width={500}
                height={500}
                src="/favicon.ico"
                alt="Reviewer Image"
                className="w-[30px] h-[30px] object-cover rounded-full"
              />
              <div className="flex flex-col gap-y-1">
                <NameText name="spacez luxe" />
                <ContentText content={`replied in ${formattedReplyDate}`} />
              </div>
            </HorizontalGap>
            <DescriptionText description={reply.replyDescription} />
          </VerticalGap>
        </div>
      )}
    </div>
  );
};

const HorizontalGap = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex items-center gap-x-4">{children}</div>;
};

const VerticalGap = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col gap-y-2.5 mobile:gap-y-4">{children}</div>
  );
};

const DescriptionText = ({ description }: { description: string }) => {
  return (
    <>
      <BodyText className="mobile:hidden text-neutralN8 !font-normal !text-[14px] !leading-[16px]">
        {description}
      </BodyText>
      <BodyText className="hidden mobile:block text-neutralN8">
        {description}
      </BodyText>
    </>
  );
};

const NameText = ({ name }: { name: string }) => {
  return (
    <BodyText className="text-neutralN9 mobile:!font-normal mobile:!text-[14px] mobile:!leading-[16px]">
      {name}
    </BodyText>
  );
};

const ContentText = ({ content }: { content: string }) => {
  return (
    <LabelText className="text-neutralN7 !font-normal !text-[12px] !leading-[14px]">
      {content}
    </LabelText>
  );
};

const Rating = ({ rating }: { rating: number }) => {
  const totalStars = 5;
  const stars = [];

  for (let i = 1; i <= totalStars; i++) {
    if (rating >= i) {
      stars.push(<FaStar color="#65605A" key={i} />);
    } else if (rating >= i - 0.5) {
      stars.push(<FaStarHalfAlt color="#65605A" key={i} />);
    } else {
      stars.push(<FaRegStar color="#65605A" key={i} />);
    }
  }

  return <div className="flex items-center gap-x-[1px]">{stars}</div>;
};
