'use client';

import { ButtonsPropType } from "@/types/common";
import Link from "next/link";

export const NeutralButton = ({
  link,
  children,
  className,
  onClick,
}: ButtonsPropType) => {
  return (
    <Link
      href={link}
      onClick={onClick}
      className={`${className} border border-neutralN7 rounded-[2.5px] px-[15px] w-auto h-[34px] flex items-center justify-center gap-x-2.5`}
    >
      {children}
    </Link>
  );
};
