import { BodyText } from "../common/texts/body-text";
import { LabelText } from "../common/texts/label-text";

export const HeaderSelectedText = ({ text }: { text: string }) => {
  return (
    <BodyText
      className={`mobile:!hidden !text-[14px] !font-normal !leading-[12px] text-primaryB7`}
    >
      {text}
    </BodyText>
  );
};

export const HeaderNotSelectedText = ({ text }: { text: string }) => {
  return (
    <LabelText className="mobile:!hidden text-primaryB7 !text-[12px] !leading-[10px] !font-light !opacity-65">{text}</LabelText>
  );
};
