import { TextsPropType } from "@/types/common";
import { Lexend_Deca } from "next/font/google";

const lexendDeca = Lexend_Deca({
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  subsets: ["latin"],
});

export const BodyText = ({ children, className }: TextsPropType) => {
  return (
    <p
      className={`${lexendDeca.className} ${className} lowercase text-[16px] mobile:text-[12px] font-normal mobile:font-normal leading-[18px] mobile:leading-[14px]`}
    >
      {children}
    </p>
  );
};
