"use client";

import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, useLoadScript, OverlayView } from "@react-google-maps/api";
import { Poi } from "@/types/common";
import { LabelText } from "@/components/common/texts/label-text";
import Link from "next/link";
import "./google-map.css";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { MapPin } from "@/components/common/map-pin";
import { mapContainerStyle, mapOptions } from "@/lib/map-styling";

export const CustomGoogleMap = ({ locations }: { locations: Poi[] }) => {
  const [isHovered, setIsHovered] = useState({ hovered: false, index: 0 });
  const isMobile = useIsMobile({});
  const targetRef = useRef<HTMLAnchorElement>(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAyVDGCUOBDxJWQWHcvonFTXAi_gwQevk8",
  });

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;

      if (targetRef.current && !targetRef.current.contains(target)) {
        setIsHovered({ hovered: false, index: 0 });
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatIndianNumber = (num: number): string => {
    return new Intl.NumberFormat("en-IN").format(num);
  };

  const initialCenter = {
    lat: isMobile ? 1.5 : 16.9505615,
    lng: isMobile ? 77 : 77.5927641,
  };

  const handleMapLoad = (map: google.maps.Map) => {
    map.setCenter(initialCenter);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={isMobile ? 4 : 5}
      onLoad={handleMapLoad}
      options={mapOptions}
    >
      {locations.map((poi, index) => (
        <OverlayView
          key={poi.key}
          position={poi.location}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={(width, height) => ({
            x: -width / 2,
            y: -height,
          })}
        >
          <Link
            ref={targetRef}
            href={isMobile ? "#" : `/${poi.id}`}
            target={isMobile ? "" : "_blank"}
            onClick={(event) => {
              if (isMobile) {
                event.preventDefault();
                if (isHovered.hovered) {
                  setIsHovered({ hovered: false, index });
                } else {
                  setIsHovered({ hovered: true, index });
                }
              }
            }}
            onMouseEnter={() => {
              if (!isMobile) {
                setIsHovered({ hovered: true, index });
              }
            }}
            onMouseLeave={() => {
              if (!isMobile) {
                setIsHovered({ hovered: false, index });
              }
            }}
            style={{
              position: "relative",
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
          >
            <LabelText
              className={`${
                isHovered.hovered && isHovered.index === index
                  ? "opacity-100"
                  : "opacity-0"
              } z-50 transition-opacity -translate-x-1/2 -top-[26px] left-[50%] absolute py-0.5 px-2 bg-neutral-300 text-primaryB7 rounded-lg`}
            >
              ₹{formatIndianNumber(poi.price)}
            </LabelText>
            <MapPin />
          </Link>
        </OverlayView>
      ))}
    </GoogleMap>
  );
};
