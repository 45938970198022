"use client";

import { Skeleton } from "@/components/ui/skeleton";
import { DesktopSearchBar } from "./desktop-search-bar";
import { useEffect, useState } from "react";
import { useUpdateSearchScroll } from "@/store/update-search-scroll";

export const SearchBar = () => {
  const [isMobile, setIsMobile] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const { setIsScrolled } = useUpdateSearchScroll((state) => state);

  useEffect(() => {
    const handleResize = () => {
      const isIt = window.innerWidth <= 475;
      setIsMobile(isIt);
    };
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const isScrolled = isMobile ? window.scrollY > 150 : window.scrollY > 500;
      setIsScrolled(isScrolled);
    });
  }, [setIsScrolled, isMobile]);

  if (!isMounted) return <SkeletonContainer />;

  return <>{isMobile ? null : <DesktopSearchBar isHeroSection />}</>;
};

const SkeletonContainer = () => {
  return (
    <Skeleton className="mobile:hidden mt-[50px] w-[60%] p-[15px] h-[80px] rounded-[2.5px]" />
  );
};
