"use client";

import { ReviewType } from "@/types/common";
import { FormEvent, useEffect, useState } from "react";
import { Review } from "./review";
import { NeutralButton } from "../buttons/neutral-button";
import { LabelText } from "../texts/label-text";
import * as gtag from "./../../../../lib/gtag"

interface Props {
  allReviews: ReviewType[];
  avgRating: number | null;
  id: string | null;
}

export const Reviews = ({ allReviews, id }: Props) => {
  const [reviews, setReviews] = useState<ReviewType[] | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    let eventName = "read_all_review_toggeled";
    if (id) {
      eventName = `${id}_read_all_review_toggeled`
    }
    if (allReviews) {
      gtag.event({
        action: eventName,
        category: 'engagement',
        label: 'Read all review togggled',
        value: 1,
      });
      if (isExpanded) {
        setIsExpanded(false);
        setReviews(allReviews?.slice(0, 3));
      } else {
        setIsExpanded(true);
        setReviews(allReviews);
      }
    }
  };
  useEffect(() => {
    if (allReviews) {
      setReviews(allReviews?.slice(0, 3));
    }
  }, [allReviews]);

  return (
    <>
      {reviews &&
        reviews.map((review, index) => (
          <Review review={review} key={review.reviewDescription + index} />
        ))}
      {allReviews.length > 3 && (
        <button className="self-start" onClick={toggleExpand}>
          <NeutralButton
            link="#"
            onClick={(event: FormEvent) => event.preventDefault()}
          >
            <LabelText className="text-primaryB7 mobile:!font-normal mobile:!text-[14px]">
              {isExpanded ? "show less reviews" : "show all reviews →"}
            </LabelText>
          </NeutralButton>
        </button>
      )}
    </>
  );
};
