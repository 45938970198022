import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/components/common/texts/body-text.tsx\",\"import\":\"Lexend_Deca\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"]}],\"variableName\":\"lexendDeca\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/(main)/_components/why-spacez.tsx\",\"import\":\"Libre_Baskerville\",\"arguments\":[{\"weight\":\"700\",\"subsets\":[\"latin\"]}],\"variableName\":\"baskervville\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/(main)/_components/hero/index.tsx\",\"import\":\"Lexend_Deca\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"700\"}],\"variableName\":\"lexendDeca\"}");
;
import(/* webpackMode: "eager", webpackExports: ["SearchBar"] */ "/vercel/path0/src/app/(main)/_components/hero/search-bar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileSearchBarModal"] */ "/vercel/path0/src/app/(main)/_components/hero/search-bar/mobile-search-bar/mobile-search-bar-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GridListView"] */ "/vercel/path0/src/app/(main)/_components/homes-for-you/grid_view_properties.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(main)/_components/homes-for-you/horizontal_list_view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomGoogleMap"] */ "/vercel/path0/src/app/(main)/_components/maps-section/google-map.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileSearchMain"] */ "/vercel/path0/src/app/(main)/_components/mobile-search-main.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BottomNavBar"] */ "/vercel/path0/src/components/bottom-nav-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Reviews"] */ "/vercel/path0/src/components/common/reviews/index.tsx");
