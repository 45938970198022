import { BodyText } from "../common/texts/body-text";
import { LabelText } from "../common/texts/label-text";

export const BookingSummarySelectedText = ({ text }: { text: string }) => {
  return (
    <BodyText className="!text-[16px] !leading-[16px] text-primaryB6">
      {text}
    </BodyText>
  );
};

export const BookingSummaryNotSelectedText = ({ text }: { text: string }) => {
  return (
    <BodyText className="!text-[16px] !leading-[16px] text-neutralN6">
      {text}
    </BodyText>
  );
};

export const InputText = ({ text }: { text: string }) => {
  return (
    <LabelText className="!text-[12px] !leading-[12px] text-primaryB7">
      {text}
    </LabelText>
  );
};
