import { SearchButton } from "@/components/common/buttons/search-button";
import { AddGuests } from "@/components/user-data-inputs/add-guests";
import { DesktopDateSelector } from "@/components/user-data-inputs/date-selector";
import { SelectDestination } from "@/components/user-data-inputs/select-destination";
import useLocalStorageSearchData from "@/hooks/use-local-storage-search-data";
import { addSearchDataInLocalStorage } from "@/lib/search-data";
import { useRouter } from "next/navigation";
import toast from "react-hot-toast";
import * as gtag from "./../../../../../../lib/gtag";
import {
  NotSelectedText,
  SelectedText,
} from "@/components/user-data-inputs/main-search-bar-texts";
import { useUpdateHomeSearchData } from "@/store/update-home-search-data";
import { useState } from "react";

type Guests = {
  noOfAdults: number;
  noOfChildren: number;
  noOfPets: number;
  noOfTotalGuests: number;
};

export const DesktopSearchBar = ({
  isHeroSection,
}: {
  isHeroSection: boolean;
}) => {
  const [isDestinationOpen, setIsDestinationOpen] = useState(false);
  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);
  const { push } = useRouter();

  const { searchData, setSearchData } = useUpdateHomeSearchData(
    (state) => state
  );

  const handleCity = (selectedCity: string) => {
    setSearchData({ ...searchData, city: selectedCity });
    if (selectedCity) {
      setIsDestinationOpen(false);
      setIsDatesModalOpen(true);
    }
  };

  const handleDates = (date: [null | Date, null | Date]) => {
    const [startDate, endDate] = date;
    setSearchData({
      ...searchData,
      checkInDate: startDate,
      checkOutDate: endDate,
    });
    if (startDate && endDate) {
      setIsDatesModalOpen(false);
      setIsGuestsOpen(true);
    }
  };

  const handleGuests = (guests: Guests) => {
    setSearchData({ ...searchData, guests: { ...guests } });
  };

  const handleSearch = () => {
    if (!searchData.checkInDate || !searchData.checkOutDate) {
      toast.error("Please select your travel dates");
      return;
    }
    if (!searchData.city) {
      toast.error("Please select your destination");
      return;
    }
    if (!searchData.guests.noOfTotalGuests) {
      toast.error("Please add guests");
      return;
    }
    if (searchData.city) {
      push(`/listings?city=${searchData.city}`);
      addSearchDataInLocalStorage(searchData);
      gtag.event({
        action: "search_click_desktop",
        category: "engagement",
        label: "Search Button",
        value: 1,
      });
    }
  };

  useLocalStorageSearchData({ setSearchData });

  return (
    <div
      className={`bg-white main-page-search-bar relative mt-[50px] w-[60%] h-auto p-[15px] pl-5 rounded-[2.5px] border-2 border-secondaryG2 justify-between flex items-center ${
        isHeroSection && "!border-neutralN6"
      }`}
    >
      <SelectDestination
        mainPage
        destination={searchData.city}
        handleSelectCity={handleCity}
        isDestinationOpen={isDestinationOpen}
        setIsDestinationOpen={setIsDestinationOpen}
      >
        {searchData.city ? (
          <NotSelectedText text="where" />
        ) : (
          <SelectedText text="where" />
        )}
      </SelectDestination>

      <div className={`h-[50px] my-auto w-[1px] bg-secondaryG2`} />

      <DesktopDateSelector
        mainPage
        isCheckIn
        checkInDate={searchData.checkInDate}
        checkOutDate={searchData.checkOutDate}
        handleDates={handleDates}
        isDatesOpen={isDatesModalOpen}
        setIsDatesOpen={setIsDatesModalOpen}
      >
        {searchData.checkInDate ? (
          <NotSelectedText text="check-in" />
        ) : (
          <SelectedText text="check in" />
        )}
      </DesktopDateSelector>

      <div className={`h-[50px] my-auto w-[1px] bg-secondaryG2`} />

      <DesktopDateSelector
        mainPage
        isCheckIn={false}
        checkInDate={searchData.checkInDate}
        checkOutDate={searchData.checkOutDate}
        handleDates={handleDates}
        isDatesOpen={isDatesModalOpen}
        setIsDatesOpen={setIsDatesModalOpen}
      >
        {searchData.checkOutDate ? (
          <NotSelectedText text="check-out" />
        ) : (
          <SelectedText text="check out" />
        )}
      </DesktopDateSelector>

      <div className={`h-[50px] my-auto w-[1px] bg-secondaryG2`} />

      <AddGuests
        guests={searchData.guests}
        mainPage
        handleGuests={handleGuests}
        isGuestsOpen={isGuestsOpen}
        setIsGuestsOpen={setIsGuestsOpen}
      >
        {searchData.guests.noOfTotalGuests ? (
          <NotSelectedText text="who" />
        ) : (
          <SelectedText text="who" />
        )}
      </AddGuests>
      <SearchButton isHeroSection={isHeroSection} handleClick={handleSearch} />
    </div>
  );
};
