import { SearchDataType } from "@/types/common";
import { Dispatch, SetStateAction } from "react";

interface ClearSearchDataProps {
  setSearchData: Dispatch<SetStateAction<SearchDataType>>;
}

export const clearSearchData = ({ setSearchData }: ClearSearchDataProps) => {
  setSearchData({
    guests: {
      noOfChildren: 0,
      noOfAdults: 0,
      noOfPets: 0,
      noOfTotalGuests: 0,
    },
    checkInDate: null,
    checkOutDate: null,
    city: "",
  });
  removeSearchDataFromLocalStorage();
};

export const addSearchDataInLocalStorage = (searchData: SearchDataType) => {
  removeSearchDataFromLocalStorage();
  const data: any = {};

  if (searchData.city) {
    data.city = searchData.city;
  }

  if (searchData.checkInDate) {
    data.checkInDate = searchData.checkInDate;
  }

  if (searchData.checkOutDate) {
    data.checkOutDate = searchData.checkOutDate;
  }

  if (searchData.guests.noOfTotalGuests) {
    data.guests = { ...searchData.guests };
  }

  const now = new Date();

  const expiryTime = now.getTime() + 10 * 60 * 60 * 1000;

  data.expiry = expiryTime;

  if (Object.keys(data).length > 0) {
    localStorage.setItem("searchData", JSON.stringify(data));
  }
};

const removeSearchDataFromLocalStorage = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("searchData");
  }
};

export const today = new Date();

export const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

export const dayAfterTomorrow = new Date(today);
dayAfterTomorrow.setDate(today.getDate() + 2);

export const isSameDay = (date1: Date) => {
  const date2 = new Date();
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const msPerDay = 24 * 60 * 60 * 1000;

export const dateDifference = (
  checkInDate: Date | null,
  checkOutDate: Date | null
) => {
  if (!checkInDate && !checkOutDate) return 0;
  //@ts-ignore
  return (new Date(checkOutDate) - new Date(checkInDate)) / msPerDay;
};
