import { GoHomeFill } from "react-icons/go";

export const MapPin = () => {
  return (
    <div
      style={{
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.18)",
      }}
      className="map-marker h-8 w-8 rounded-full bg-primaryB6 flex items-center justify-center relative"
    >
      <div
        style={{
          transform: "translate(-50%, 0px) rotate(45deg)",
          boxSizing: "border-box",
        }}
        className="map-marker-pointer absolute w-2.5 h-2.5 bg-primaryB6 rounded-[2px] -bottom-0.5 left-1/2"
      ></div>
      <GoHomeFill
        size={15}
        color="white"
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    </div>
  );
};
