import { LabelText } from "../common/texts/label-text";
import { TitleText } from "../common/texts/title-text";

export const SelectedText = ({ text }: { text: string }) => {
  return (
    <TitleText
      className={`mobile:!hidden !text-[18px] !font-medium !leading-[14px] text-primaryB7`}
    >
      {text}
    </TitleText>
  );
};

export const NotSelectedText = ({ text }: { text: string }) => {
  return (
    <LabelText className="mobile:!hidden text-primaryB7 !leading-[14px] opacity-[56%]">{text}</LabelText>
  );
};
