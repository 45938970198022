"use client";

import StickyBox from "react-sticky-box";
import { MobileSearchBar } from "./hero/search-bar/mobile-search-bar";
import { useUpdateShowNavMobile } from "@/store/update-show-nav-mobile";

export const MobileSearchMain = () => {
  const { showHeaderNav, shadowSearchBar } = useUpdateShowNavMobile(
    (state) => state
  );

  return (
    <StickyBox
      offsetTop={showHeaderNav ? 45 : 0}
      className="hidden mobile:block w-full p-5 bg-white z-[41]"
    >
      <MobileSearchBar />
    </StickyBox>
  );
};
