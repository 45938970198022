import toast from "react-hot-toast";
import { BodyText } from "./texts/body-text";
import { LabelText } from "./texts/label-text";

interface Props {
  guestType: string;
  noOfGuest: number;
  setNoOfGuest: (update: (prev: number) => number) => void;
  maxGuests?: number;
  totalGuests?: number;
  labelText?: string;
}

export const Guest = ({
  guestType,
  noOfGuest,
  setNoOfGuest,
  maxGuests,
  totalGuests,
  labelText,
}: Props) => {
  return (
    <div className="w-full bg-white border border-neutralN6 p-2.5 rounded-[2.5px] flex items-center justify-between">
      <div className="flex flex-col gap-y-2.5">
        {labelText ? (
          <LabelText className="text-neutralN7 !text-[12px] !leading-[10px] !font-light">
            {labelText}
          </LabelText>
        ) : null}
        <BodyText className="text-primaryB6 !text-[14px] !leading-[12px] !font-normal">
          {guestType}
        </BodyText>
      </div>
      <div className="flex items-center gap-x-4">
        <button
          onClick={() =>
            setNoOfGuest((prev: number) => {
              if (noOfGuest <= 0) return prev;
              return (prev -= 1);
            })
          }
        >
          {" "}
          <BodyText className="!text-[14px] !leading-[14px] text-primaryB2">
            -
          </BodyText>
        </button>
        <BodyText className="!text-[16px] !leading-[14px] text-white w-[25px] h-[25px] rounded-full bg-primaryB4 flex items-center justify-center">
          {noOfGuest}
        </BodyText>
        <button
          onClick={() =>
            setNoOfGuest((prev: number) => {
              if (
                (guestType === "Adults" && noOfGuest >= 20) ||
                (guestType === "Children" && noOfGuest >= 10) ||
                (guestType === "Pets" && noOfGuest >= 5)
              )
                return prev;
              if (maxGuests && totalGuests && totalGuests >= maxGuests) {
                toast.error("You exceeded the maximum guests limit!");
                return prev;
              }
              return (prev += 1);
            })
          }
        >
          <BodyText className="!text-[16px] !leading-[14px] text-primaryB2">
            +
          </BodyText>
        </button>
      </div>
    </div>
  );
};
